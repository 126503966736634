<template>
  <v-card min-height="100%">
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Partita IVA"
            rules="required|max:13"
          >
            <v-text-field
              v-model="item.fullVatNumber"
              :counter="13"
              :error-messages="errors"
              label="Partita IVA"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Ragione Sociale"
            rules="required|max:255"
          >
            <v-text-field
              v-model="item.name"
              :counter="255"
              :error-messages="errors"
              label="Ragione Sociale"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <v-checkbox
            v-model="item.enabled"
            label="Abilitato"
            @change="isDirty = true"
          ></v-checkbox>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="orange"
        @click="goBackward()"
      >
        Indietro
      </v-btn>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="red"
        @click="
          item = {};
          isDirty = false;
        "
      >
        Annulla
      </v-btn>
      <v-btn
        v-if="isDirty"
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="green"
        @click="submitData()"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import createedit from "@/mixins/createedit.mixin";
import client from "@/mixins/client.mixin";
import auth from "@/mixins/auth.mixin";

export default {
  mixins: [createedit, client, auth],
  name: "CreateClient",
};
</script>

<style scoped></style>
