export default {
  data() {
    return {
      subcollection: "operations",
      collection: "clients",
      postSaveRouteName: "listClientRoute",
      uniqueIndex: "fullVatNumber",
    };
  },
};
