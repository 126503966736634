import Vue from "vue";
import Vuex from "vuex";

import md5 from "md5";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user == null) {
        commit("SET_USER", null);
        return;
      }
      commit("SET_USER", {
        gravatar: `https://www.gravatar.com/avatar/${md5(user.email)}`,
        displayName: user.displayName,
        email: user.email,
      });
    },
  },
});
