import Vue from "vue";
import App from "./App.vue";
import "./assets/scss/custom.scss";

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import router from "./router";

import vuetify from "./plugins/vuetify";
import store from "./store/store";

import moment from "moment";
moment.locale("it");

Vue.filter("toCurrency", function (value) {
  if (typeof value === "string") {
    value = Number(value);
  }
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("formatDate", function (value) {
  if (value) {
    if (typeof value == "object") {
      if (typeof value.toDate == "function") {
        value = value.toDate();
      }
    }
    if (typeof value !== "number") {
      value = String(value);
    }
    return moment(new Date(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatDateTime", function (value) {
  if (value) {
    if (typeof value == "object") {
      if (typeof value.toDate == "function") {
        value = value.toDate();
      }
    }
    if (typeof value !== "number") {
      value = String(value);
    }
    return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyD6qqDuvplrS0Cl1j9dm9icuCg3XwwiyF8",
  authDomain: "magiandocrm.firebaseapp.com",
  projectId: "magiandocrm",
  storageBucket: "magiandocrm.appspot.com",
  messagingSenderId: "493021371712",
  appId: "1:493021371712:web:736d5fb1292a120a8a2ea5",
  measurementId: "G-GBV3Y7QB6Z",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false;

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch("fetchUser", user);

  new Vue({
    render: (h) => h(App),
    router,
    store,
    vuetify,
  }).$mount("#app");
});
