import firebase from "firebase/app";
import "firebase/firestore";

export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    loadAllData() {
      this.loading = true;
      const collection = firebase.firestore().collection(this.collection);
      collection.onSnapshot((snap) => {
        let items = [];
        for (let ii = 0; ii < snap.size; ii++) {
          let item = snap.docs[ii].data();
          item.id = snap.docs[ii].id;
          items.push(item);
        }

        this.items = items;

        this.loading = false;
      });
    },
    loadAllSubData(iid, orderBy = null) {
      this.loading = true;

      const database = firebase.firestore();
      const basecollection = database.collection(this.collection);
      const doc = basecollection.doc(iid);
      let collection = doc.collection(this.subcollection);
      if (orderBy) {
        collection = collection.orderBy(orderBy);
      }
      collection.onSnapshot((snap) => {
        let items = [];
        for (let ii = 0; ii < snap.size; ii++) {
          let item = snap.docs[ii].data();
          item.id = snap.docs[ii].id;
          items.push(item);
        }

        this.items = items;

        this.loading = false;
      });
    },
  },
};
