<template>
  <v-form action="#" @submit.prevent="submit">
    <v-text-field v-model="form.email" label="E-mail" required></v-text-field>

    <v-text-field
      v-model="form.password"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required, rules.min]"
      :type="showPassword ? 'text' : 'password'"
      label="Password"
      hint="At least 8 characters"
      counter
      @click:append="showPassword = !showPassword"
    ></v-text-field>
    <v-btn depressed elevation="2" large @click="submit">Register</v-btn>
  </v-form>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import auth from "@/mixins/auth.mixin";

export default {
  mixins: [auth],
  data() {
    return {
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      error: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.form.email, this.form.password)
        .then((data) => {
          data.user
            .updateProfile({
              displayName: this.form.name,
            })
            .then(() => {});
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
  name: "Register",
};
</script>

<style scoped></style>
