<template>
  <v-app id="app">
    <v-navigation-drawer v-if="user.loggedIn" v-model="drawer" app light>
      <v-list-item light>
        <v-list-item-content>
          <logo width="6em"></logo>
          <v-list-item-subtitle
            >gestione credito prepagato</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav dark color="blue darken-2">
        <v-list-item
          :to="route.path"
          v-for="route in menuRoutes"
          :key="route.path"
          link
        >
          <v-list-item-icon v-if="route.meta.menu.icon">
            <v-icon>{{ route.meta.menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ route.meta.menu.text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="user.loggedIn"
      app
      elevate-on-scroll
      fixed
      dark
      color="blue darken-2"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <v-avatar class="mr-2" size="40">
        <v-img :src="user.data.gravatar" />
      </v-avatar>
      <v-btn
        v-if="user.loggedIn"
        depressed
        color="white"
        icon
        @click.prevent="signOut"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main app class="pt-0">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app light color="blue lighten-4"> Magiando Srl </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store/store";
import firebase from "firebase/app";
import "firebase/auth";
import auth from "@/mixins/auth.mixin";
import Logo from "@/components/logo/Logo";

export default {
  name: "App",
  mixins: [auth],

  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({
            name: "loginRoute",
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
    menuRoutes() {
      return this.$router.options.routes.filter(function (route) {
        return (
          route.meta && route.meta.menu && route.meta.menu.enabled === true
        );
      });
    },
  },
  store,
  components: { Logo },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
