import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import it from "vuetify/es5/locale/it";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {},
  lang: {
    locales: { it },
    current: "it",
  },
});
