<template>
  <v-card min-height="100%">
    <v-data-iterator
      :items="items"
      :items-per-page="items.length"
      :page="1"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar dark color="blue darken-2" class="mb-1">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Cerca"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              solo-inverted
              hide-details
              :items="keys"
              value="value"
              prepend-inner-icon="mdi-magnify"
              label="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle v-model="sortDesc" mandatory>
              <v-btn large depressed color="blue" :value="false">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn large depressed color="blue" :value="true">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>
      <template v-slot="props">
        <v-row class="pa-2">
          <v-col
            class="ma-0"
            v-for="item in props.items"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                <v-btn
                  class="ma-2"
                  x-small
                  icon
                  color="indigo"
                  @click="goTo('listCreditRoute', item.id)"
                >
                  <v-icon> mdi-eye </v-icon>
                </v-btn>
                {{ item.name }}
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-content
                    :class="{
                      'blue--text': sortBy === key.value,
                    }"
                  >
                    {{ key.text }}:
                  </v-list-item-content>
                  <v-list-item-content
                    class="d-flex flex-column"
                    :class="{ 'blue--text': sortBy === key.value }"
                  >
                    <div v-if="key.type == 'currency'">
                      {{ item[key.value] | toCurrency }}
                    </div>
                    <div v-if="key.type != 'currency'">
                      {{ item[key.value] }}
                    </div>
                    <v-btn
                      v-if="key.route && key.icon"
                      class="ma-2"
                      x-small
                      @click="goTo(key.route, item.id)"
                      icon
                      color="green"
                    >
                      <v-icon>
                        {{ key.icon }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-progress-circular
                class="mb-3 mt-3"
                :rotate="-90"
                :size="200"
                :width="40"
                :value="item.percentage"
                :color="item.percentage < 100 ? 'green lighten-1' : 'red'"
              >
                <span class="text-h4">{{ item.percentage }}%</span>
              </v-progress-circular>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import auth from "@/mixins/auth.mixin";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  data() {
    return {
      search: "",
      filter: {},
      sortDesc: false,
      sortBy: "name",
      items: [],
      keys: [
        { value: "name", text: "Nome" },
        { value: "fullVatNumber", text: "Partita IVA" },
        {
          value: "total",
          text: "Totale",
          type: "currency",
          route: "addCreditRoute",
          icon: "mdi-plus",
        },
        {
          value: "spent",
          text: "Speso",
          type: "currency",
          route: "removeCreditRoute",
          icon: "mdi-minus",
        },
      ],
      error: null,
    };
  },
  mixins: [auth],
  name: "Dashboard",
  computed: {
    filteredKeys() {
      return this.keys.filter((key) => {
        return key.value !== "name";
      });
    },
  },
  methods: {},
  mounted() {
    const collection = firebase.firestore().collection("clients");

    collection.onSnapshot((snapo) => {
      this.items = [];
      for (let ii = 0; ii < snapo.size; ii++) {
        let client = snapo.docs[ii].data();
        client.id = snapo.docs[ii].id;
        const doc = collection.doc(client.id);
        const subcollection = doc.collection("operations");
        (client.total = 0), (client.spent = 0);

        subcollection.onSnapshot((snap) => {
          for (let ii = 0; ii < snap.size; ii++) {
            let item = snap.docs[ii].data();
            if (item.type == "add") {
              client.total += parseFloat(item.value);
            } else {
              client.spent += parseFloat(item.value);
            }
          }

          const percentage = Math.round(
            (client.spent / client.total + Number.EPSILON) * 100
          );
          console.log(percentage);
          client.percentage = client.total > 0 ? percentage : 0;
          this.items.push(client);
        });
      }
    });
  },
};
</script>

<style scoped></style>
