<template>
  <v-card min-height="100%">
    <v-simple-table>
      <template v-slot>
        <thead>
          <tr>
            <th class="text-left"></th>
            <th class="text-left">Nome</th>
            <th class="text-left">Partita Iva</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <v-icon
                small
                outlined
                fab
                color="red"
                class="mr-2"
                @click="confirmDeleteItem(item.id, item.name)"
              >
                mdi-trash-can-outline
              </v-icon>
              <v-icon
                small
                outlined
                fab
                color="orange"
                class="mr-2"
                @click="goTo('editClientRoute', item.id)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                outlined
                fab
                color="green"
                class="mr-2"
                @click="goTo('addCreditRoute', item.id)"
              >
                mdi-plus
              </v-icon>
              <v-icon
                small
                outlined
                fab
                color="red"
                class="mr-2"
                @click="goTo('removeCreditRoute', item.id)"
              >
                mdi-minus
              </v-icon>
              <v-icon
                small
                outlined
                fab
                color="indigo"
                class="mr-2"
                @click="goTo('listCreditRoute', item.id)"
              >
                mdi-eye
              </v-icon>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.fullVatNumber }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Cancellazione </v-card-title>

        <v-card-text
          >Confermi la cancellazione di {{ deleteDialogName }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            elevation="2"
            class="ma-2"
            outlined
            color="green"
            @click="deleteDialog = false"
          >
            annulla
          </v-btn>

          <v-btn
            elevation="2"
            class="ma-2"
            outlined
            color="red"
            @click="deleteItem(deleteDialogItem)"
          >
            conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import auth from "@/mixins/auth.mixin";
import createedit from "@/mixins/createedit.mixin";
import list from "@/mixins/list.mixin";
import client from "@/mixins/client.mixin";

export default {
  mixins: [auth, list, client, createedit],
  data() {
    return {
      filters: {},
      loading: true,
      total: 0,
      options: {},
      deleteDialog: false,
      deleteDialogItem: null,
      deleteDialogName: null,
    };
  },
  methods: {
    confirmDeleteItem(iid, name) {
      this.deleteDialog = true;
      this.deleteDialogName = name;
      this.deleteDialogItem = iid;
    },
  },
  mounted() {
    this.loadAllData();
  },
  name: "ListClient",
};
</script>

<style scoped></style>
