<template>
  <svg viewBox="0 0 72.75 42" :width="width" xmlns="http://www.w3.org/2000/svg">
    <title>logo magiando</title>

    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="44"
        width="74.75"
        y="-1"
        x="-1"
      />
    </g>
    <g>
      <title>Layer 1</title>
      <path
        id="svg_1"
        d="m7.02,22.06l-2.44,6.28l-2.46,-6.28l-1.26,5.8l-0.86,0l1.9,-8.7l2.68,7l2.67,-7l1.9,8.7l-0.86,0l-1.27,-5.8z"
      />
      <path
        id="svg_2"
        d="m17.52,27.86l-0.87,-2.22l-3.59,0l-0.87,2.22l-0.92,0l3.59,-8.72l3.57,8.72l-0.91,0zm-1.15,-3l-1.48,-3.75l-1.52,3.75l3,0z"
      />
      <path
        id="svg_3"
        d="m28.73,24.55a4,4 0 0 1 -1.45,2.45a4.18,4.18 0 0 1 -2.68,1a4.09,4.09 0 0 1 -1.63,-0.33a4.22,4.22 0 0 1 -1.33,-0.9a4.27,4.27 0 0 1 -0.9,-1.34a4.11,4.11 0 0 1 -0.33,-1.64a4.06,4.06 0 0 1 0.33,-1.63a4.16,4.16 0 0 1 0.9,-1.34a4.22,4.22 0 0 1 1.33,-0.9a3.89,3.89 0 0 1 1.55,-0.33a4.21,4.21 0 0 1 2.58,0.83a4.09,4.09 0 0 1 1.53,2.18l-0.88,0a3.35,3.35 0 0 0 -1.24,-1.58a3.36,3.36 0 0 0 -4.28,5.14a3.17,3.17 0 0 0 2.37,1a3.43,3.43 0 0 0 2,-0.64a3.32,3.32 0 0 0 1.27,-1.7l-3.3,0l0,-0.73l4.24,0l-0.08,0.46z"
      />
      <path id="svg_4" d="m32.14,27.86l0,-8.16l0.84,0l0,8.16l-0.84,0z" />
      <path
        id="svg_5"
        d="m42.14,27.86l-0.88,-2.22l-3.58,0l-0.88,2.22l-0.91,0l3.58,-8.72l3.58,8.72l-0.91,0zm-1.19,-3l-1.48,-3.75l-1.48,3.75l2.96,0z"
      />
      <path
        id="svg_6"
        d="m46.79,27.86l-0.84,0l0,-8.67l4.59,6.51l0,-6l0.83,0l0,8.66l-4.58,-6.5l0,6z"
      />
      <path
        id="svg_7"
        d="m61.06,25.42a3.92,3.92 0 0 1 -0.89,1.29a4.07,4.07 0 0 1 -1.33,0.84a4.27,4.27 0 0 1 -1.64,0.31l-2.14,0l0,-8.16l2.14,0a4.47,4.47 0 0 1 1.64,0.31a4.09,4.09 0 0 1 1.33,0.85a3.92,3.92 0 0 1 0.89,1.29a4,4 0 0 1 0.33,1.63a4,4 0 0 1 -0.33,1.64zm-1.47,-4a3.31,3.31 0 0 0 -2.37,-0.92l-1.32,0l0,6.48l1.32,0a3.3,3.3 0 0 0 2.37,-0.91a3.23,3.23 0 0 0 0,-4.65z"
      />
      <path
        id="svg_8"
        d="m72.37,25.39a4.08,4.08 0 0 1 -0.89,1.34a4.27,4.27 0 0 1 -1.34,0.9a4.19,4.19 0 0 1 -3.26,0a4.22,4.22 0 0 1 -1.33,-0.9a4.27,4.27 0 0 1 -0.9,-1.34a4.17,4.17 0 0 1 0.9,-4.59a4.22,4.22 0 0 1 1.33,-0.9a4.19,4.19 0 0 1 3.26,0a4.27,4.27 0 0 1 1.34,0.9a4.14,4.14 0 0 1 0.89,4.59zm-1.49,-4a3.32,3.32 0 1 0 1,2.36a3.2,3.2 0 0 0 -0.96,-2.35l-0.04,-0.01z"
      />
      <path
        id="svg_9"
        d="m20.37,0.92l0,-0.92l5.88,0l2.2,1.92l0,11l1.92,0l1.86,-1.62l0,-10.4l-1.59,0l0,-0.92l7.69,0l0,12.94l1.85,0l1.86,-1.62l0,-10.4l-1.59,0l0,-0.92l9.81,0l0,0.92l-2.12,0l0,12l3.23,0l0,0.96l-6.89,0l-1.88,-1.62l-1.85,1.62l-6.08,0l-1.88,-1.62l-1.86,1.62l-5.94,0l-2.46,-2.15l0,-10.79l-2.16,0zm5.3,0l-2,0l0,10.4l1.86,1.62l1.72,0l0,-10.63l-1.58,-1.39zm7.69,0l0,10.4l1.86,1.62l1.85,0l0,-12l-3.71,-0.02zm9.81,0l0,10.4l1.86,1.62l1.85,0l0,-12l-3.71,-0.02z"
      />
      <path
        id="svg_10"
        d="m9.15,32.98l0.16,0l0,3.37l2,0l0,0.16l-2.15,0l-0.01,-3.53z"
      />
      <path
        id="svg_11"
        d="m13.08,35.33a1.14,1.14 0 0 0 0.13,0.57a0.81,0.81 0 0 0 0.35,0.36a1,1 0 0 0 0.52,0.13a1,1 0 0 0 0.52,-0.13a0.86,0.86 0 0 0 0.37,-0.36a1.14,1.14 0 0 0 0.13,-0.57l0,-2.35l0.17,0l0,2.37a1.33,1.33 0 0 1 -0.16,0.65a1.13,1.13 0 0 1 -0.43,0.41a1.36,1.36 0 0 1 -1.2,0a1,1 0 0 1 -0.42,-0.41a1.33,1.33 0 0 1 -0.16,-0.65l0,-2.37l0.18,0l0,2.35z"
      />
      <path
        id="svg_12"
        d="m16.85,36.51l1.18,-1.85l0.1,0.14l-1.07,1.71l-0.21,0zm0.05,-3.53l0.2,0l2.3,3.53l-0.21,0l-2.29,-3.53zm1.2,1.63l1,-1.63l0.27,0l-1.13,1.76l-0.14,-0.13z"
      />
      <path
        id="svg_13"
        d="m21.16,35.33a1.14,1.14 0 0 0 0.13,0.57a0.89,0.89 0 0 0 0.36,0.36a1,1 0 0 0 0.52,0.13a1,1 0 0 0 0.52,-0.13a0.89,0.89 0 0 0 0.36,-0.36a1.15,1.15 0 0 0 0.14,-0.57l0,-2.35l0.18,0l0,2.37a1.23,1.23 0 0 1 -0.16,0.65a1,1 0 0 1 -0.42,0.41a1.38,1.38 0 0 1 -1.21,0a1.08,1.08 0 0 1 -0.41,-0.41a1.23,1.23 0 0 1 -0.16,-0.65l0,-2.37l0.17,0l-0.02,2.35z"
      />
      <path
        id="svg_14"
        d="m26.05,32.98a1.49,1.49 0 0 1 0.36,0a1.35,1.35 0 0 1 0.34,0.17a1,1 0 0 1 0.27,0.3a1,1 0 0 1 0.09,0.45a1.24,1.24 0 0 1 -0.05,0.38a0.83,0.83 0 0 1 -0.2,0.32a0.76,0.76 0 0 1 -0.34,0.23a1.26,1.26 0 0 1 -0.51,0.09l-0.56,0l0,1.54l-0.17,0l0,-3.53l0.77,0.05zm-0.06,1.83a1.24,1.24 0 0 0 0.47,-0.08a0.79,0.79 0 0 0 0.29,-0.2a0.85,0.85 0 0 0 0.15,-0.28a1,1 0 0 0 0,-0.3a0.87,0.87 0 0 0 -0.06,-0.32a0.76,0.76 0 0 0 -0.18,-0.26a0.88,0.88 0 0 0 -0.28,-0.17a1,1 0 0 0 -0.35,-0.06l-0.62,0l0,1.72l0.58,-0.05zm0.49,0.05l1,1.65l-0.2,0l-1,-1.65l0.2,0z"
      />
      <path
        id="svg_15"
        d="m30.13,35.22l-1.32,-2.24l0.21,0l1.23,2.1l-0.06,0l1.23,-2.11l0.19,0l-1.31,2.24l0,1.29l-0.17,0l0,-1.28z"
      />
      <path
        id="svg_16"
        d="m37.22,33.3a3.07,3.07 0 0 0 -0.37,-0.13a1.74,1.74 0 0 0 -0.45,-0.06a0.93,0.93 0 0 0 -0.6,0.18a0.59,0.59 0 0 0 -0.22,0.47a0.53,0.53 0 0 0 0.12,0.35a1.38,1.38 0 0 0 0.33,0.23l0.42,0.18l0.36,0.15a1.61,1.61 0 0 1 0.31,0.19a0.89,0.89 0 0 1 0.23,0.28a0.9,0.9 0 0 1 0.09,0.42a0.91,0.91 0 0 1 -0.15,0.52a1,1 0 0 1 -0.38,0.35a1.25,1.25 0 0 1 -0.56,0.12a2,2 0 0 1 -0.5,-0.07a2.16,2.16 0 0 1 -0.37,-0.16l-0.23,-0.13l0.08,-0.15a1.13,1.13 0 0 0 0.27,0.16a1.73,1.73 0 0 0 0.34,0.14a1.31,1.31 0 0 0 0.38,0.06a1.13,1.13 0 0 0 0.45,-0.09a1,1 0 0 0 0.35,-0.28a0.79,0.79 0 0 0 0.14,-0.47a0.68,0.68 0 0 0 -0.13,-0.44a0.82,0.82 0 0 0 -0.34,-0.27a3.43,3.43 0 0 0 -0.44,-0.19l-0.34,-0.14a1.46,1.46 0 0 1 -0.3,-0.17a0.64,0.64 0 0 1 -0.22,-0.24a0.67,0.67 0 0 1 -0.08,-0.33a0.69,0.69 0 0 1 0.13,-0.43a0.78,0.78 0 0 1 0.34,-0.28a1.14,1.14 0 0 1 0.49,-0.11a2.1,2.1 0 0 1 0.48,0.06a2,2 0 0 1 0.43,0.15l-0.06,0.13z"
      />
      <path
        id="svg_17"
        d="m39.12,32.98l2.15,0l0,0.16l-2,0l0,1.51l1.78,0l0,0.21l-1.78,0l0,1.54l2,0l0,0.16l-2.21,0l0.06,-3.58z"
      />
      <path
        id="svg_18"
        d="m43.85,32.98a1.49,1.49 0 0 1 0.36,0a1.35,1.35 0 0 1 0.34,0.17a0.83,0.83 0 0 1 0.26,0.3a0.88,0.88 0 0 1 0.1,0.45a1,1 0 0 1 -0.06,0.38a0.8,0.8 0 0 1 -0.19,0.32a0.76,0.76 0 0 1 -0.34,0.23a1.26,1.26 0 0 1 -0.51,0.09l-0.56,0l0,1.54l-0.17,0l0,-3.53l0.77,0.05zm-0.06,1.83a1.24,1.24 0 0 0 0.47,-0.08a0.79,0.79 0 0 0 0.29,-0.2a0.85,0.85 0 0 0 0.15,-0.28a1,1 0 0 0 0,-0.3a0.87,0.87 0 0 0 -0.06,-0.32a0.76,0.76 0 0 0 -0.18,-0.26a0.88,0.88 0 0 0 -0.28,-0.17a1,1 0 0 0 -0.35,-0.06l-0.62,0l0,1.72l0.58,-0.05zm0.48,0.05l1,1.65l-0.2,0l-1,-1.65l0.2,0z"
      />
      <path
        id="svg_19"
        d="m46.84,32.98l1.35,3.43l-0.09,0l1.33,-3.42l0.18,0l-1.48,3.69l-1.48,-3.69l0.19,-0.01z"
      />
      <path id="svg_20" d="m51.24,32.98l0.13,0l0,3.53l-0.17,0l0.04,-3.53z" />
      <path
        id="svg_21"
        d="m55.87,36.28a1.71,1.71 0 0 1 -0.27,0.14a1.68,1.68 0 0 1 -0.32,0.09a1.5,1.5 0 0 1 -0.37,0a1.94,1.94 0 0 1 -0.73,-0.14a1.71,1.71 0 0 1 -0.56,-0.39a1.54,1.54 0 0 1 -0.36,-0.57a1.72,1.72 0 0 1 -0.13,-0.68a1.89,1.89 0 0 1 0.14,-0.72a1.77,1.77 0 0 1 0.38,-0.58a1.61,1.61 0 0 1 0.57,-0.39a1.65,1.65 0 0 1 0.7,-0.14a1.63,1.63 0 0 1 0.5,0.07a2,2 0 0 1 0.44,0.19l-0.07,0.15a2.2,2.2 0 0 0 -0.41,-0.18a1.57,1.57 0 0 0 -1.09,0.06a1.7,1.7 0 0 0 -0.86,0.89a1.7,1.7 0 0 0 -0.13,0.64a1.68,1.68 0 0 0 0.12,0.63a1.56,1.56 0 0 0 0.32,0.52a1.71,1.71 0 0 0 0.51,0.35a1.76,1.76 0 0 0 0.66,0.13a1.72,1.72 0 0 0 0.47,-0.07a2.1,2.1 0 0 0 0.4,-0.18l0.09,0.18z"
      />
      <path
        id="svg_22"
        d="m57.59,32.98l2.14,0l0,0.16l-2,0l0,1.51l1.77,0l0,0.21l-1.77,0l0,1.54l2,0l0,0.16l-2.21,0l0.07,-3.58z"
      />
      <path
        id="svg_23"
        d="m63.26,33.3l-0.37,-0.13a1.88,1.88 0 0 0 -0.46,-0.06a0.9,0.9 0 0 0 -0.59,0.18a0.6,0.6 0 0 0 -0.23,0.47a0.54,0.54 0 0 0 0.13,0.35a1.14,1.14 0 0 0 0.32,0.23l0.43,0.18l0.35,0.15a1.69,1.69 0 0 1 0.32,0.19a0.8,0.8 0 0 1 0.31,0.7a1,1 0 0 1 -0.14,0.52a1,1 0 0 1 -0.39,0.35a1.2,1.2 0 0 1 -0.55,0.12a1.89,1.89 0 0 1 -0.5,-0.07a1.86,1.86 0 0 1 -0.37,-0.16l-0.24,-0.13l0.08,-0.15a1.75,1.75 0 0 0 0.27,0.16a1.83,1.83 0 0 0 0.35,0.14a1.26,1.26 0 0 0 0.38,0.06a1.2,1.2 0 0 0 0.45,-0.09a1,1 0 0 0 0.35,-0.28a0.79,0.79 0 0 0 0.14,-0.47a0.68,0.68 0 0 0 -0.14,-0.44a0.82,0.82 0 0 0 -0.34,-0.27a3.3,3.3 0 0 0 -0.43,-0.19l-0.34,-0.14a1.54,1.54 0 0 1 -0.31,-0.17a0.73,0.73 0 0 1 -0.22,-0.24a0.67,0.67 0 0 1 -0.08,-0.33a0.75,0.75 0 0 1 0.13,-0.43a0.81,0.81 0 0 1 0.35,-0.28a1.1,1.1 0 0 1 0.49,-0.11a2.09,2.09 0 0 1 0.47,0.06a2.05,2.05 0 0 1 0.44,0.15l-0.06,0.13z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logo",
  props: {
    width: {
      type: [String],
      default: "2in",
    },
  },
};
</script>

<style scoped></style>
