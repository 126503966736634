import firebase from "firebase/app";
import "firebase/database";

import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} non può essere vuoto",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isDirty: false,
      errors: null,
      item: {},
      originalIndexValue: null,
      subitem: {},
    };
  },
  methods: {
    deleteItem(iid) {
      const database = firebase.firestore();
      const index = database.collection("indexes");
      const ref = database.collection(this.collection).doc(iid);
      ref.get().then((doc) => {
        const indexRef = index.doc(
          `${this.collection}/${this.uniqueIndex}/${
            doc.data()[this.uniqueIndex]
          }`
        );
        const batch = database.batch();

        batch.delete(ref);
        batch.delete(indexRef);

        batch
          .commit()
          .then(() => {
            this.deleteDialog = false;
          })
          .catch((reason) => {
            console.log(reason);
          });
      });
    },
    submitSubData(iid) {
      const database = firebase.firestore();
      const batch = database.batch();
      const basecollection = database.collection(this.collection);
      const doc = basecollection.doc(iid);
      const collection = doc.collection(this.subcollection);
      const ref = collection.doc();
      batch.set(ref, this.subitem);
      batch
        .commit()
        .then(() => {
          this.isDirty = false;
          this.$router.push({ name: this.postSaveRouteName });
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
    submitData() {
      const database = firebase.firestore();
      const batch = database.batch();
      const index = database.collection("indexes");
      const uniqueIndexValue = this.item[this.uniqueIndex];

      index
        .doc(`${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}`)
        .get()
        .then((value) => {
          if (value.exists) {
            this.error = true;
          } else {
            const collection = database.collection(this.collection);
            const ref = collection.doc();
            batch.set(ref, this.item);

            const indexRef = index.doc(
              `${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}`
            );
            batch.set(indexRef, {
              value: ref.id,
            });
            batch
              .commit()
              .then(() => {
                this.isDirty = false;
                this.$router.push({ name: this.postSaveRouteName });
              })
              .catch((reason) => {
                console.log(reason);
              });
          }
        });
    },
    loadData(iid) {
      this.loading = true;
      return new Promise((resolve) => {
        const database = firebase.firestore();
        database
          .collection(this.collection)
          .doc(iid)
          .get()
          .then((doc) => {
            this.item = doc.data();
            this.originalIndexValue = this.item[this.uniqueIndex];
            this.loading = false;
            resolve(true);
          });
      });
    },
    updateData(iid) {
      const database = firebase.firestore();
      const batch = database.batch();
      const index = database.collection("indexes");
      const uniqueIndexValue = this.item[this.uniqueIndex];

      index
        .doc(`${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}`)
        .get()
        .then((value) => {
          console.log(value.exists);
          console.log(value.id);
          console.log(value.data());
          if (value.exists && uniqueIndexValue !== this.originalIndexValue) {
            this.error = true;
          } else {
            const collection = database.collection(this.collection);
            const ref = collection.doc(iid);

            batch.set(ref, this.item);

            if (uniqueIndexValue !== this.originalIndexValue) {
              const oldIndexRef = index.doc(
                `${this.collection}/${this.uniqueIndex}/${this.originalIndexValue}`
              );
              batch.delete(oldIndexRef);

              const indexRef = index.doc(
                `${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}`
              );
              batch.set(indexRef, {
                value: ref.id,
              });
            }
            batch
              .commit()
              .then(() => {
                this.isDirty = false;
                this.$router.push({ name: this.postSaveRouteName });
              })
              .catch((reason) => {
                console.log(reason);
              });
          }
        });
    },
  },
};
