<template>
  <v-card min-height="100%">
    <v-card-title>{{ item.name }}</v-card-title>
    <v-row>
      <v-col cols="12" sm="5" md="4" lg="3" xl="2">
        <v-date-picker
          full-width
          v-model="dateFilter"
          type="month"
          :max="filterMax"
          :min="filterMin"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="9" xl="10">
        <v-simple-table>
          <template v-slot>
            <thead>
              <tr>
                <th class="text-center">Dettagli</th>
                <th class="text-center">Valore</th>
                <th class="text-center">Data</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Precedente a {{ beforeDate | formatDate }}</td>
                <td>{{ totalBefore | toCurrency }}</td>
                <td></td>
              </tr>
              <tr v-for="item in filteredItems" :key="item.name">
                <td>{{ item.reference }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.date }}</td>
              </tr>
              <tr>
                <td>Rimanente alla data {{ afterDate | formatDate }}</td>
                <td>{{ (totalItem + totalBefore) | toCurrency }}</td>
                <td></td>
              </tr>
              <tr>
                <td>Successivo a {{ afterDate | formatDate }}</td>
                <td>{{ totalAfter | toCurrency }}</td>
                <td></td>
              </tr>
              <tr>
                <th class="text-center">Rimanente</th>
                <td>{{ total | toCurrency }}</td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <a v-if="currentDocument" v-show="false" href="" ref="binarydownload"
        >&nbsp;</a
      >
      <v-tooltip v-if="currentDocument" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            elevation="2"
            class="ma-2"
            outlined
            x-large
            color="green"
            @click="download"
            dark
            v-bind="attrs"
            v-on="on"
          >
            scarica report
            <v-icon left> mdi-alert-circle-outline </v-icon>
          </v-btn>
        </template>
        <span v-if="currentDocument">
          Scarica report versione {{ currentDocument.version }} generato il
          {{ currentDocument.data | formatDateTime }} da
          {{ currentDocument.user }}
        </span>
      </v-tooltip>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        :color="currentDocument ? 'orange' : 'green'"
        @click="generate"
      >
        <span v-if="!currentDocument">genera report</span>
        <span v-else>sostituisci report</span>
      </v-btn>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="orange"
        @click="goBackward()"
      >
        Indietro
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import auth from "@/mixins/auth.mixin";
import list from "@/mixins/list.mixin";
import createedit from "@/mixins/createedit.mixin";
import addoperations from "@/mixins/addoperations.mixin";
import pizZipUtils from "pizzip/utils";
import Pizzip from "pizzip";
import docxtemplater from "docxtemplater";
import firebase from "firebase/app";
import "firebase/database";

export default {
  mixins: [list, createedit, addoperations, auth],
  data() {
    return {
      templateUrl: "https://magiandocrmcdn.web.app/template-list.docx",
      dateFilter: new Date().toISOString().substr(0, 7),
      subcollectionname: "documents",
      binarycollectionname: "docbinary",
      currentDocument: null,
    };
  },
  watch: {
    dateFilter(newDate) {
      this.getDocument(newDate);
    },
  },
  methods: {
    getDocument(month) {
      const database = firebase.firestore();
      const index = database.collection("indexes");
      const uniqueIndexValue = this.item[this.uniqueIndex];
      const indexPath = `${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}/${this.subcollectionname}/${month}`;
      index
        .doc(indexPath)
        .get()
        .then((value) => {
          if (value.exists) {
            const iid = this.$route.params.id;
            const basecollection = database.collection(this.collection);
            const doc = basecollection.doc(iid);
            const subcollection = doc.collection(this.subcollectionname);
            const ref = subcollection.doc(value.data().value);
            ref.get().then((doc) => {
              this.currentDocument = doc.data();
            });
          } else {
            this.currentDocument = null;
          }
        });
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    submitDocument(blob, binding) {
      const iid = this.$route.params.id;
      const database = firebase.firestore();
      const batch = database.batch();
      const index = database.collection("indexes");
      const uniqueIndexValue = this.item[this.uniqueIndex];
      const indexPath = `${this.collection}/${this.uniqueIndex}/${uniqueIndexValue}/${this.subcollectionname}/${this.dateFilter}`;

      index
        .doc(indexPath)
        .get()
        .then((value) => {
          const basecollection = database.collection(this.collection);
          const doc = basecollection.doc(iid);
          const subcollection = doc.collection(this.subcollectionname);
          const binarycollection = doc.collection(this.binarycollectionname);

          let ref, binaryref;
          if (value.exists) {
            binaryref = binarycollection.doc(value.data().value);
            ref = subcollection.doc(value.data().value);
          } else {
            binaryref = binarycollection.doc();
            ref = subcollection.doc();
          }
          this.blobToBase64(blob).then((base64) => {
            // do what you wanna do
            const cd = {
              data: binding.currentDateTime,
              version: binding.version,
              binaryref: binaryref.id,
              user: binding.userEmail,
            };
            batch.set(ref, cd);

            batch.set(binaryref, {
              base64,
              filename: binding.filename,
            });

            const indexRef = index.doc(indexPath);
            batch.set(indexRef, {
              value: ref.id,
            });
            batch
              .commit()
              .then(() => {
                this.currentDocument = cd;
              })
              .catch((reason) => {
                console.error(reason);
              });
          });
        });
    },
    loadFile(callback) {
      pizZipUtils.getBinaryContent(this.templateUrl, callback);
    },
    download() {
      const database = firebase.firestore();
      const iid = this.$route.params.id;
      const basecollection = database.collection(this.collection);
      const doc = basecollection.doc(iid);
      const subcollection = doc.collection(this.binarycollectionname);
      const ref = subcollection.doc(this.currentDocument.binaryref);
      ref.get().then((doc) => {
        this.$refs.binarydownload.href = doc.data().base64;
        this.$refs.binarydownload.download = doc.data().filename;
        this.$refs.binarydownload.click();
      });
    },
    generate() {
      let _this = this;
      _this.loadFile(function (error, content) {
        if (error) {
          throw error;
        }

        // The error object contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
        function replaceErrors(key, value) {
          if (value instanceof Error) {
            return Object.getOwnPropertyNames(value).reduce(function (
              error,
              key
            ) {
              error[key] = value[key];
              return error;
            },
            {});
          }
          return value;
        }

        function errorHandler(error) {
          console.error(JSON.stringify({ error }, replaceErrors));

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.error("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }

        var zip = new Pizzip(content);
        var doc;
        try {
          doc = new docxtemplater(zip);
        } catch (error) {
          // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
          errorHandler(error);
        }

        let currentversion = _this.currentDocument
          ? _this.currentDocument.version
          : 0;

        const now = new Date();

        const binding = {
          clientName: _this.item.name,
          clientVat: _this.item.fullVatNumber,
          userDisplayName: firebase.auth().currentUser.displayName,
          userEmail: firebase.auth().currentUser.email,
          dateFilter: _this.$options.filters.formatDate(_this.dateFilter),
          totalAfter: _this.totalAfter,
          beforeDate: _this.$options.filters.formatDate(_this.beforeDate),
          afterDate: _this.$options.filters.formatDate(_this.afterDate),
          totalBefore: _this.$options.filters.toCurrency(_this.totalBefore),
          items: _this.filteredItems,
          totalItem: _this.$options.filters.toCurrency(_this.totalItem),
          totalFiltered: _this.$options.filters.toCurrency(
            _this.totalBefore + _this.totalItem
          ),
          currentDateTimeFormatted: _this.$options.filters.formatDateTime(now),
          currentDateTime: now,
          version: currentversion + 1,
          filename: `${_this.item.fullVatNumber}_${_this.dateFilter}_v${
            currentversion + 1
          }.docx`,
        };

        doc.setData(binding);
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
          errorHandler(error);
        }

        var out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }); //Output the document using Data-URI

        _this.submitDocument(out, binding);
      });
    },
    checkCurrentMonth(obj) {
      return (
        obj !== undefined &&
        obj.date !== undefined &&
        obj.date.substr(0, 7) === this.dateFilter
      );
    },
    beforeFilter(obj) {
      return (
        obj !== undefined &&
        obj.date !== undefined &&
        new Date(obj.date) < this.beforeDate
      );
    },
    afterFilter(obj) {
      return (
        obj !== undefined &&
        obj.date !== undefined &&
        new Date(obj.date) > this.afterDate
      );
    },
    calculateProgressive(pitems) {
      if (pitems.length === 0) return [];
      let v = [],
        lastv = 0;
      pitems.forEach((a) => {
        lastv += (a.type === "add" ? 1 : -1) * parseFloat(a.value);
        v.push(lastv);
      });
      return v;
    },
  },
  computed: {
    filterMax() {
      if (this.items.length === 0) return new Date().toISOString();
      const dates = this.items.map(function (item) {
        return new Date(item.date);
      });
      dates.push(new Date());
      return new Date(Math.max.apply(null, dates)).toISOString();
    },
    filterMin() {
      if (this.items.length === 0) return new Date().toISOString();
      const dates = this.items.map(function (item) {
        return new Date(item.date);
      });
      dates.push(new Date());
      return new Date(Math.min.apply(null, dates)).toISOString();
    },
    beforeDate() {
      return new Date(this.dateFilter);
    },
    afterDate() {
      return new Date(
        this.beforeDate.getFullYear(),
        this.beforeDate.getMonth() + 1,
        1
      );
    },
    beforeItems() {
      if (this.items.length === 0) return [];
      return this.items.filter(this.beforeFilter);
    },
    afterItems() {
      if (this.items.length === 0) return [];
      return this.items.filter(this.afterFilter);
    },
    totalItem() {
      const tb = this.calculateProgressive(
        this.items.filter(this.checkCurrentMonth)
      );

      if (tb.length === 0) return 0;
      return tb[tb.length - 1];
    },
    filteredItems() {
      if (this.items.length === 0) return [];
      return this.items.filter(this.checkCurrentMonth).map((item) => {
        const d = {
          reference: item.reference,
          date: this.$options.filters.formatDate(item.date),
          value: this.$options.filters.toCurrency(
            (item.type !== "add" ? -1 : 1) * item.value
          ),
        };
        console.log(item);
        return d;
      });
    },
    labels() {
      if (this.items.length === 0) return [];
      let v = [];
      this.items.forEach((a) => {
        v.push(a.date);
      });
      return v;
    },
    progressive() {
      return this.calculateProgressive(this.items);
    },
    totalBefore() {
      const tb = this.calculateProgressive(this.beforeItems);
      if (tb.length === 0) return 0;
      return tb[tb.length - 1];
    },
    totalAfter() {
      const tb = this.calculateProgressive(this.afterItems);
      if (tb.length === 0) return 0;
      return tb[tb.length - 1];
    },
    total() {
      if (this.progressive.length === 0) return 0;
      return this.progressive[this.progressive.length - 1];
    },
  },
  mounted() {
    this.loadData(this.$route.params.id).then(() => {
      this.getDocument(this.dateFilter);
    });
    this.loadAllSubData(this.$route.params.id, "date");
  },
  name: "ListCredit",
};
</script>

<style scoped></style>
