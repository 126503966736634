<template>
  <v-card flat light class="mp-6">
    <v-container fluid ma-0 pa-0 fill-height>
      <v-row>
        <v-col
          offset="1"
          cols="10"
          lg="2"
          offset-lg="5"
          class="d-flex justify-center mb-6 mt-6"
        >
          <logo width="35vw"></logo>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset="1" cols="10" lg="6" offset-lg="3">
          <v-form action="#" @submit.prevent="submit">
            <v-text-field
              v-model="form.email"
              label="E-mail"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-btn
              elevation="2"
              class="ma-2"
              outlined
              x-large
              color="black"
              @click="submit"
              >login</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import auth from "@/mixins/auth.mixin";
import Logo from "@/components/logo/Logo";

export default {
  components: { Logo },
  mixins: [auth],
  data() {
    return {
      showPassword: false,
      form: {
        email: "",
        password: "",
      },
      error: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    submit() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then((user) => {
          this.store.dispatch("fetchUser", user);
          this.$router.replace({ name: "dashboardRoute" });
        })
        .catch((err) => {
          this.error = err.message;
        });
    },
  },
  name: "Login",
};
</script>

<style scoped></style>
