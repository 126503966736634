<template>
  <v-card min-height="100%">
    <v-card-text>
      <validation-observer ref="observer">
        <form>
          <validation-provider
            v-slot="{ errors }"
            name="Importo"
            rules="required"
          >
            <v-text-field
              v-model="subitem.value"
              :error-messages="errors"
              label="Importo"
              type="number"
              @input="isDirty = true"
              required
            ></v-text-field>
          </validation-provider>
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider
                v-slot="{ errors }"
                name="Data"
                rules="required"
              >
                <v-text-field
                  v-model="subitem.date"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error-messages="errors"
                  @input="isDirty = true"
                  required
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </validation-provider>
            </template>
            <v-date-picker
              v-model="subitem.date"
              @input="
                isDirty = true;
                datePicker = false;
              "
            ></v-date-picker>
          </v-menu>
          <validation-provider
            v-slot="{ errors }"
            name="Dettagli Operazione"
            rules="required|max:2048"
          >
            <v-textarea
              v-model="subitem.reference"
              :counter="2048"
              :error-messages="errors"
              label="Dettagli Operazione"
              @input="isDirty = true"
              required
            ></v-textarea>
          </validation-provider>
        </form>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="orange"
        @click="goBackward()"
      >
        Indietro
      </v-btn>
      <v-btn
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="red"
        @click="
          item = {};
          isDirty = false;
        "
      >
        Annulla
      </v-btn>
      <v-btn
        v-if="isDirty"
        elevation="2"
        class="ma-2"
        outlined
        x-large
        color="green"
        @click="submitSubData($route.params.id)"
      >
        Salva
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import addoperations from "@/mixins/addoperations.mixin";
import createedit from "@/mixins/createedit.mixin";
import auth from "@/mixins/auth.mixin";

export default {
  mixins: [createedit, addoperations, auth],
  data() {
    return {
      datePicker: false,
    };
  },
  mounted() {
    this.subitem.type = "sub";
    this.loadData(this.$route.params.id);
  },
  name: "RemoveCredit",
};
</script>

<style scoped></style>
