import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/login/Login";
import Register from "@/components/register/Register";
import Dashboard from "@/components/dashboard/Dashboard";
import CreateClient from "@/components/clients/CreateClient";
import ListClient from "@/components/clients/ListClient";
import EditClient from "@/components/clients/EditClient";
import AddCredit from "@/components/clients/operations/AddCredit";
import RemoveCredit from "@/components/clients/operations/RemoveCredit";
import ListCredit from "@/components/clients/operations/ListCredit";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: { name: "loginRoute" },
    },
    {
      path: "/login",
      name: "loginRoute",
      component: Login,
      meta: {
        mustAnonymous: true,
      },
    },
    {
      path: "/register",
      name: "registerRoute",
      component: Register,
      meta: {
        mustAnonymous: true,
      },
    },
    {
      path: "/dashboard",
      name: "dashboardRoute",
      component: Dashboard,
      meta: {
        menu: {
          enabled: true,
          text: "Dashboard",
        },
        authenticated: true,
      },
    },
    {
      path: "/client/create",
      name: "createClientRoute",
      component: CreateClient,
      meta: {
        menu: {
          enabled: true,
          text: "Nuovo Cliente",
        },
        authenticated: true,
      },
    },
    {
      path: "/client/list",
      name: "listClientRoute",
      component: ListClient,
      meta: {
        menu: {
          enabled: true,
          text: "Clienti",
        },
        authenticated: true,
      },
    },
    {
      path: "/client/edit/:id",
      name: "editClientRoute",
      component: EditClient,
      meta: {
        authenticated: true,
      },
    },
    {
      path: "/client/credit/add/:id",
      name: "addCreditRoute",
      component: AddCredit,
      meta: {
        authenticated: true,
      },
    },
    {
      path: "/client/credit/remove/:id",
      name: "removeCreditRoute",
      component: RemoveCredit,
      meta: {
        authenticated: true,
      },
    },
    {
      path: "/client/credit/list/:id",
      name: "listCreditRoute",
      component: ListCredit,
      meta: {
        authenticated: true,
      },
    },
  ],
});

export default router;
