import store from "@/store/store";
import { mapGetters } from "vuex";

export default {
  watch: {},
  methods: {
    goTo(route, iid) {
      this.$router.push({ name: route, params: { id: iid } });
    },
    goBackward() {
      this.$router.go(-1);
    },
  },
  store,
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  mounted() {
    if (this.$route.meta.mustAnonymous && this.user.loggedIn) {
      this.$router.replace({ name: "dashboardRoute" });
    }
    if (this.$route.meta.authenticated && !this.user.loggedIn) {
      this.$router.replace({ name: "loginRoute" });
    }
  },
};
